import { Component } from "react";

import './Search-Box.styles.css'

class SearchBox extends Component {


    render(){


        const { handleflightnumber } = this.props
       // console.log('the prop object is',this.props)
        return(
            <div className="search">
            <h1>Flight Search </h1>
            <input className='search-box' 
            type='search' 
            placeholder='Search by flight #'
            onKeyDown={(event)=> this.props.handleflightnumber(event)}/>
            </div>
        );
    }
}
export default SearchBox;