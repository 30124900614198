import { Component } from "react";
import './Return-Result.styles.css'

class ReturnResult extends Component {

    handleUrlConstruct(item){
        let coords = [{ lat:item.lat , lon: item.lon }];
        let coordinateString = '';
        
        for (const item of coords) {
            coordinateString += '!4m3!3m2!1d' + item.lat + '!2d' + item.lon;
        }
        
        const epochNow = Date.now();
        
        let urlConstruct = `https://www.google.com/maps/embed?pb=!1m${coords.length * 4 + 16}`;
        urlConstruct += `!1m12!1m3!1d1.0!2d${coords[0].lon}!3d${coords[0].lat}`;
        urlConstruct += `!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1`;
        urlConstruct += `!4m${coords.length * 4 + 1}!3e0${coordinateString}`;
        urlConstruct += `!5e0!3m2!1sen!2sau!4v${epochNow}000!5m2!1sen!2sau`;
        return urlConstruct;
    }


    render(){

        
        const { flightsData } = this.props
       //console.log(flightsData)
        return(
            <div className="card">
    
            <div className="depart">
            {flightsData.map((item)=><td> {item.departure.airport.name} --> </td>)} 
            </div> 
            <div className="arrive">
            {flightsData.map((item)=><td>{item.arrival.airport.name} --> </td>)}
            </div>
            
            <div className='time'>
            {flightsData.map((item)=><td> {new Date(item.departure.scheduledTimeUtc).toUTCString()}  </td>)}
            </div>

            <div className='time'>
            {flightsData.map((item)=><td>{new Date(item.arrival.scheduledTimeUtc).toUTCString()}  </td> )}
            </div>
            

           {/* <div className="lat">
            {flightsData.map((item)=><td>{item.departure.airport.location?.lat}</td>)} 
            </div>
           
            <div className="lon">
            {flightsData.map((item)=><td>{item.departure.airport.location?.lon}</td>)} 
           
        </div>*/}

            </div>
        );
    }
}
export default ReturnResult;