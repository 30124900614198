import { Component } from 'react';
    

import ReturnResult from './components/Return-Result/ReturnResult.components';
import './App.css';
import SearchBox from './components/Search-Box/SearchBox.components';


class App extends Component {


  baseUrl = 'https://aerodatabox.p.rapidapi.com' ;
  //flightNumber = 'et512';

  constructor(){
    super();
    this.handleflightnumber=this.handleflightnumber.bind(this)
    this.state ={flightsData:[]}
    
  }
  

  
 handleflightnumber(event){
    if(event.key === 'Enter'){
        
        const url = this.baseUrl+'/flights/number/'  +  event.target.value + '?withAircraftImage=false&withLocation=false'
        console.log('the fetch url is',url)


      const options = {
        method: 'GET',
        headers: {
          'X-RapidAPI-Key': 'f9be85c60emsh7746cca70a83d43p13f849jsn343a9686c88a',
          'X-RapidAPI-Host': 'aerodatabox.p.rapidapi.com'
        }
      };
      
      fetch(url, options)
        .then((response) => response.json())
        .then((data) => this.setState((prevState)=>({flightsData:data})))
        .catch(err => console.error(err));

        }
 }



    render() {
      let coords = [{ lat: '37.7749', lon: '-122.4194' }];
let coordinateString = '';

for (const item of coords) {
    coordinateString += '!4m3!3m2!1d' + item.lat + '!2d' + item.lon;
}

const epochNow = Date.now();

let urlConstruct = `https://www.google.com/maps/embed?pb=!1m${coords.length * 4 + 16}`;
urlConstruct += `!1m12!1m3!1d1.0!2d${coords[0].lon}!3d${coords[0].lat}`;
urlConstruct += `!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1`;
urlConstruct += `!4m${coords.length * 4 + 1}!3e0${coordinateString}`;
urlConstruct += `!5e0!3m2!1sen!2sau!4v${epochNow}000!5m2!1sen!2sau`;
      return (
        <div className="App">
       
     
         < SearchBox handleflightnumber = {this.handleflightnumber} />
         < ReturnResult flightsData={this.state.flightsData} />
         <div className='map'><iframe src=""></iframe>

      </div> 
         
        </div>
      );
     }
    }

export default App;
